import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { API, getAuthData, getToken } from 'util/API';

/**
 * Create a primary document submission, and return the data, which includes the newly created doc's
 * ID and a pre-signed URL to upload content to.
 *
 * @param docSubmission
 * @param token
 * @returns {Promise<any>}
 */
const createPrimaryDoc = async (docSubmission, token) => {
  const primaryDocRes = await fetch(`${API}/auth/uploadPrimaryIntakeDoc`, {
    body: JSON.stringify({
      doc_type: docSubmission.docType,
      document_din: `${format(new Date(), 'yyyyMMdd')}-IR-${docSubmission.title}${
        docSubmission.lsaNumber
      }${docSubmission.docType}A`,
      lsa_doc_number: docSubmission.lsaNumber,
      filename: docSubmission.primaryDocument.name,
      filetype: docSubmission.primaryDocument.mimeType,
      notes: docSubmission.notes,
      title: docSubmission.title
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  if (!primaryDocRes.ok) {
    throw new Error('Failed to create primary doc');
  }
  const primaryData = await primaryDocRes.json();
  return primaryData;
};

/**
 * Uploads content to a pre-signed URL
 * @param presignedPutUrl
 * @param doc
 * @returns {Promise<void>}
 */
const uploadToPresignedURL = async (presignedPutUrl, doc) => {
  const contentRes = await fetch(presignedPutUrl, {
    body: doc.data,
    headers: {
      'Content-Type': doc.mimeType
    },
    method: 'PUT'
  });

  if (!contentRes.ok) {
    throw new Error(`Failed to upload ${doc.name}`);
  }
};

/**
 * Upload a suppporting document - first calls the `uploadSupportingIntakeDoc` endpoint, and then
 * uploads the document to th pre-signed URL that the endpoint returns.
 *
 * @param doc
 * @param title
 * @param primaryDocId
 * @param step
 * @param token
 * @returns {Promise<void>}
 */
const uploadSupportingDoc = async (doc, title, primaryDocId, step, token) => {
  const supDocRes = await fetch(`${API}/auth/uploadSupportingIntakeDoc`, {
    body: JSON.stringify({
      filename: doc.name,
      filetype: doc.mimeType,
      intake_primary_document_id: primaryDocId,
      primary_doc_title: title
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  if (!supDocRes.ok) {
    throw new Error(`Failed to create ${doc.name}`);
  }
  const supDocData = await supDocRes.json();
  await uploadToPresignedURL(supDocData.presignedPutUrl, doc, step);
};

/**
 * Reducer to maintain the state of the hook.
 * @param state The current state
 * @param action The action to perform
 * @returns {(*&{currentStep: *})|(*&{state: string})|{currentStep: number, state: string, steps: [{label: string, status: string},{label, status: string}]}}
 */
const uploadReducer = (state, action) => {
  switch (action.type) {
    // Set the state when the upload starts
    case 'start': {
      const steps = [
        { label: 'Creating Document Submission', status: 'active' },
        { label: action.payload.primaryDocument.name, status: '' }
      ];
      if (action.payload.supportingDocuments?.length) {
        action.payload.supportingDocuments.forEach((doc) => {
          steps.push({ label: doc.name, status: '' });
        });
      }
      return {
        currentStep: 0,
        state: '',
        steps
      };
    }

    // advance to the next step
    case 'advance': {
      const newStep = state.currentStep + 1;
      const newState = {
        ...state,
        currentStep: newStep
      };
      newState.steps[state.currentStep].status = 'complete';
      if (newStep < state.steps.length) {
        newState.steps[newStep].status = 'active';
      } else {
        newState.state = 'complete';
      }
      return newState;
    }

    // set an error state
    case 'error': {
      const newState = {
        ...state,
        state: 'error'
      };
      newState.steps[state.currentStep].status = 'error';
      return newState;
    }
  }
};

/**
 * Hook to encapsulate creating a document submission, adding content and supporting documents,
 * while maintaining a status that a UI component can use to provide progress feedback to the user.
 *
 * @returns {{submitDocument: ((function(*): Promise<void>)|*), status: S}}
 */
export const useSubmitDocument = () => {
  const [status, dispatch] = useReducer(uploadReducer, { state: '', steps: [], currentStep: 0 });
  const history = useHistory();

  const submitDocument = useCallback(
    async (docSubmission) => {
      dispatch({
        payload: docSubmission,
        type: 'start'
      });

      try {
        // need a token...
        const token = await getToken(history);
        // create the primary doc...
        const primaryData = await createPrimaryDoc(docSubmission, token);
        dispatch({ type: 'advance' });

        // upload content...
        await uploadToPresignedURL(primaryData.presignedPutUrl, docSubmission.primaryDocument, 1);
        dispatch({ type: 'advance' });

        // if supporting docs...
        if (docSubmission.supportingDocuments?.length) {
          for (const doc of docSubmission.supportingDocuments) {
            await uploadSupportingDoc(
              doc,
              docSubmission.title,
              primaryData.id,
              status.currentStep,
              token
            );
            dispatch({
              type: 'advance'
            });
          }
        }
      } catch (e) {
        dispatch({ type: 'error' });
      }
    },
    [history, status.currentStep]
  );

  return {
    status,
    submitDocument
  };
};

export const useDocumentSubmissions = () => {
  const history = useHistory();
  const submissionsUrl = `${API}/auth/getIntakeDocumentSubmissions`;
  const authQueryFn = () => getAuthData(history, submissionsUrl, []);

  return useQuery({
    queryKey: ['submissions', submissionsUrl],
    queryFn: authQueryFn,
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response
  });
};
