import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { useSubmitDocument } from 'hooks/INtakeHooks';
import { UploadStatusIcon } from 'pages/MyIAR/Home/INtake/INtakeAgencyPortal/UploadStatusIcon/UploadStatusIcon';
import { useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { AgencyPortalForm } from './AgencyPortalForm/AgencyPortalForm';
import styles from './INtakeAgencyPortal.module.css';

export const INtakeAgencyPortal = () => {
  const dialogRef = useRef(null);
  const { status, submitDocument } = useSubmitDocument();

  // prevent closing dialog on ESC
  useLayoutEffect(() => {
    const dialog = dialogRef.current;
    const promptEscapeListener = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
      }
    };
    dialog.addEventListener('keydown', promptEscapeListener);

    return () => {
      dialog?.removeEventListener('keydown', promptEscapeListener);
    };
  }, []);

  const handleSubmit = (docSubmission) => {
    // show status in a modal dialog, but maybe it should be a new "view"
    dialogRef.current?.showModal();
    submitDocument(docSubmission);
  };

  return (
    <>
      <Helmet>
        <title>INtake Agency Portal | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        INtake Agency Portal
      </AccentHeader>

      <AgencyPortalForm onSubmit={handleSubmit} />

      <dialog className={styles.statusDialog} ref={dialogRef}>
        <h3 className={styles.statusTitle}>Submitting Document...</h3>
        <ol className={styles.statusList}>
          {status.steps.map((step) => (
            <li key={step.label} className={styles.statusItem}>
              <span>{step.label}</span>
              <UploadStatusIcon status={step.status} />
            </li>
          ))}
        </ol>
        <div className={styles.dialogButtons}>
          <Button
            action={() => dialogRef.current.close()}
            ariaLabel="Close Status"
            disabled={!status.state}
          >
            {status.state === 'complete'
              ? 'Complete'
              : status.state === 'error'
              ? 'Close'
              : 'Working...'}
          </Button>
        </div>
      </dialog>
    </>
  );
};
