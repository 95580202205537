import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * When links such as "http://www.in.gov/legislative/iac/T03201/A00010.PDF" redirect to our app,
 * we need to then route them to the latest Indiana Admin Code Article. The route mapping, in this
 * example, gives us the path params title = 03201 and article = 00010. These need to be parsed
 * to tile = 320.1, article 1 in order to redirect. Even though these are PDF links, we want to
 * display the HTML article (content that is not available on the old site), and allow the user to
 * download the PDF from there.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ArticleRedirect = () => {
  const { title, article } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});

  const parseValue = (val) => {
    let parsedVal;
    if (val.endsWith('0')) {
      // remove the trailing zero
      parsedVal = val.slice(0, -1);
    } else {
      // insert a decimal before the last number
      parsedVal = val.slice(0, -1) + '.' + val.slice(-1);
    }

    // remove leading zeroes...
    const leadingZeroes = /^0+/;
    return parsedVal.replace(leadingZeroes, '');
  };

  useEffect(() => {
    const t = parseValue(title);
    const a = parseValue(article);
    setState({ title: t, article: a });
    history.replace(`/latestArticle/${t}/${a}`);
  }, [article, history, title]);

  // Users should not see this, but just in case it doesn't properly redirect...
  return (
    <MainContentWrapper>
      <Link
        to={`/latestArticle/${state.title}/${state.article}`}
        aria-label={`link to title ${state.title}, article ${state.article}`}
      >
        Title {state.title}, {state.title}
      </Link>
    </MainContentWrapper>
  );
};
