import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

/**
 * Handle redirections from URLs such as "http://iac.iga.in.gov/iac//iac_title?iact=320.1" to the
 * latest IAC Title.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const TitleRedirect = () => {
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const title = params.get('iact');

  useEffect(() => {
    history.replace(`/latestArticle/${title}`);
  }, [history, title]);

  // Users should not see this, but just in case it doesn't properly redirect...
  return (
    <MainContentWrapper>
      <Link to={`/latestArticle/${title}`} aria-label={`link to title ${title}`}>
        Title {title}
      </Link>
    </MainContentWrapper>
  );
};
