import html2pdf from 'html3pdf/src';

/**
 * Convert a doc number into the desired display value
 * @param {string} docNumber LSA doc number
 * @returns {string} a displayable LSA doc number, or the original
 * docNumber if it is not a string.
 */
export const displayLsaDocNumber = (docNumber) => {
  if (!docNumber || typeof docNumber !== 'string') {
    return docNumber;
  }
  const year = docNumber.substring(0, 2);
  const number = parseInt(docNumber.substring(2));
  return `${year}-${number}`;
};

/**
 * Convert a displayable lsa number to our 0-padded version
 * @param {string} docNumber
 * @returns {string}
 */
export const encodedLsaDocNumber = (docNumber) => {
  if (!docNumber || typeof docNumber !== 'string') {
    return docNumber;
  }
  const [year, number] = docNumber.split('-');
  return `${year}${number.padStart(4, '0')}`;
};

export const docGroup = {
  all: 'all',
  ag: 'ag_opinions',
  agencyGuidance: 'agency_guidance',
  gov: 'gov',
  pending: 'pending_rules',
  ir: 'ir'
};

/**
 * Download document PDF by converting document XML to PDF using xml-to-pdf utility
 * @param {*} filename the desired download filename (exclude extension)
 * @param {*} xml the document xml
 */
export const downloadPDF = async (filename, xml) => {
  const PDF_API = process.env['REACT_APP_PDF_GEN_URL'];
  try {
    const response = await fetch(`${PDF_API}/xml-to-pdf`, {
      body: JSON.stringify({ xml, filename }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Failed to download PDF');
    }

    // trigger a download from the response data
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers.get('Content-Disposition');

    const parsedFilename = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/"/g, filename)
      : 'downloaded-file';

    // create a temporary link to trigger the download
    link.download = parsedFilename;
    document.body.appendChild(link);
    link.click();

    // cleanup the temporary link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (e) {
    throw new Error('Failed to download PDF');
  }
};

/**
 * DEPRECATED: JS solution for rendering a PDF from HTML, this has been replaced by the Python version above: downloadPDF().
 * Keeping around for posterity. Relies on import of "html2pdf from 'html3pdf/src'"
 *
 * Generate a PDF from the specified element, save as the specified filename
 * @param element The element to use for the PDF content
 * @param filename Filename when saved
 * @param cleanupFunc Function to clean up anything once the PDF generation is complete
 */
export const downloadPDFFromHtml = (element, filename, cleanupFunc) => {
  html2pdf()
    .set({
      filename: filename,
      html2canvas: { useCORS: true },
      jsPDF: {
        format: 'letter',
        orientation: 'portrait',
        unit: 'in'
      },
      margin: 0.75,
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      }
    })
    .from(element)
    .save()
    .then(() => {
      if (cleanupFunc) {
        cleanupFunc();
      }
    });
};
