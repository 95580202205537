import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminContext } from 'components/AdminContext/AdminContext';
import { AuthContext } from 'components/AuthContext/AuthContext';
import { compareDesc } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import formatISO from 'date-fns/formatISO';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { API, getAuthData, getData, getToken } from 'util/API';
import { parseServerDate, todayFn } from 'util/dateUtil';
import {
  claims,
  documentStagePrivate,
  documentStagePrivateReady,
  documentStagePublic
} from 'util/shapes';

const docStageUrl = `${API}/auth/docStage`;

const registerDocsKey = 'registerDocs';
/**
 * Retrieve metadata for IR Documents.
 *
 * If the document stage is non-public, use the Cognito token to make
 * an authorized request.
 *
 * @param {Date} from The earliest document date to return.
 * @param {Date} to The latest document date to return.
 * @param stage the document stage, defaults to value in AdminContext
 * @returns react-query "useQuery" response
 */
export const useIrDocs = (from, to, stage) => {
  const history = useHistory();
  const { docStage } = useContext(AdminContext);
  const stageToUse = stage || docStage;

  const isPublic = stageToUse === documentStagePublic;

  const fromISO = from ? formatISO(from, { representation: 'date' }) : null;
  const toISO = to ? formatISO(to, { representation: 'date' }) : null;

  const docsURL = (fromStr, toStr) => {
    const params = new URLSearchParams();
    params.append('start_date', fromStr);
    params.append('end_date', toStr);
    params.append('doc_stage', stageToUse);

    const urlPath = isPublic ? '/registerDocs' : '/auth/registerDocs';

    return `${API}${urlPath}?${params.toString()}`;
  };

  const possiblyAuthorized = isPublic
    ? () => getData(docsURL(fromISO, toISO))
    : () => getAuthData(history, docsURL(fromISO, toISO), []);

  return useQuery({
    queryKey: [registerDocsKey, fromISO, toISO, stageToUse],
    queryFn: possiblyAuthorized,
    enabled: !!fromISO && !!toISO,
    notifyOnChangeProps: ['error', 'data', 'isLoading'],
    select: (response) => response?.iar_ir_doc_list
  });
};

const registerRelatedDocsKey = 'registerRelatedDocs';
/**
 * Retrieve metadata for Related Documents associated with a given document.
 *
 * @param {string} docNumber The LSA Doc Number of the document to find related documents for.
 * @returns react-query "useQuery" response, with docs sorted by descending date_posted
 */
export const useIrRelatedDocs = (docNumber) => {
  const history = useHistory();
  const { docStage } = useContext(AdminContext);

  const isPublic = docStage === documentStagePublic;

  const relatedDocsURL = (docNumber) => {
    const params = new URLSearchParams();
    params.append('lsa_doc', docNumber);
    params.append('doc_stage', docStage);

    const urlPath = isPublic ? '/registerRelatedDocs' : '/auth/registerRelatedDocs';

    return `${API}${urlPath}?${params.toString()}`;
  };

  const possiblyAuthorized = isPublic
    ? () => getData(relatedDocsURL(docNumber))
    : () => getAuthData(history, relatedDocsURL(docNumber), []);

  return useQuery({
    queryKey: [registerRelatedDocsKey, docNumber, docStage],
    queryFn: possiblyAuthorized,
    enabled: !!docNumber,
    notifyOnChangeProps: ['error', 'data'],
    select: (response) =>
      response?.ir_related_doc_list?.toSorted((a, b) => {
        const aPosted = parseServerDate(a.date_posted);
        const bPosted = parseServerDate(b.date_posted);
        return compareDesc(aPosted, bPosted);
      })
  });
};

const registerDocKey = 'registerDoc';
/**
 * Retrieve an Indiana Register Document by DIN.
 *
 * @param din Document Identification Number (not really a number)
 * @param onDemand whether or not to execute the query on demand only (via refetch)
 * @returns react-query "useQuery" response
 */
export const useIrDocument = (din, onDemand) => {
  const history = useHistory();
  const { userClaims } = useContext(AuthContext);

  const isAdmin = userClaims.includes(claims.isAdmin);
  const docStage = isAdmin ? '*' : 'public';

  const documentURL = (din) => {
    const params = new URLSearchParams();
    params.append('din', din);
    params.append('doc_stage', docStage);

    const urlPath = isAdmin ? '/auth/registerDoc' : '/registerDoc';

    return `${API}${urlPath}?${params.toString()}`;
  };

  const possiblyAuthorized = isAdmin
    ? () => getAuthData(history, documentURL(din))
    : () => getData(documentURL(din));

  return useQuery({
    queryKey: [registerDocKey, din, docStage, isAdmin],
    queryFn: possiblyAuthorized,
    enabled: !!din && !onDemand,
    notifyOnChangeProps: ['error', 'data', 'isLoading'],
    select: (response) => response?.iar_ir_doc
  });
};

const adminCodeEditionsKey = 'adminCodeEditions';
/**
 * Retrieve the list of Indiana Admin Code editions.
 *
 * @returns react-query "useQuery" response
 */
export const useEditions = () => {
  const editionsUrl = `${API}/adminCodeEditions`;

  return useQuery({
    queryKey: [adminCodeEditionsKey, editionsUrl],
    queryFn: () => getData(editionsUrl),
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    // data comes back oldest to newest, so reverse it.
    // Maybe we need a real sort, though.
    select: (response) => {
      return response?.iar_iac_edition_list
        .map((edition) => {
          return {
            label: edition.name,
            value: `${edition.edition_year}`
          };
        })
        .reverse();
    }
  });
};

const adminCodeTreeKey = 'adminCodeTree';
/**
 * Retrieve the list of title/articles for the specified editionId
 * @param editionYear year of the edition
 * @returns react-query "useQuery" response
 */
export const useTitleList = (editionYear) => {
  const history = useHistory();
  const { docStage } = useContext(AdminContext);

  const isPublic = docStage === documentStagePublic;

  const titleListUrl = isPublic
    ? `${API}/adminCodeTree?edition_year=${editionYear}&doc_stage=${docStage}`
    : `${API}/auth/adminCodeTree?edition_year=${editionYear}&doc_stage=${docStage}`;

  const possiblyAuthorized = isPublic
    ? () => getData(titleListUrl)
    : () => getAuthData(history, titleListUrl, []);

  return useQuery({
    queryKey: [adminCodeTreeKey, titleListUrl, editionYear],
    queryFn: possiblyAuthorized,
    enabled: !!editionYear,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) =>
      response?.iar_iac_title_article_list?.map((title) => {
        return {
          ...title,
          displayName: `${title.title_num} - ${title.title_name}`,
          label: `${title.title_num} - ${title.title_name}`,
          value: title.title_num
        };
      })
  });
};

const adminCodeArticleKey = 'adminCodeArticle';
/**
 * Retrieve the article by edition year, title number, and article number
 * @param article the article number
 * @param title the title number
 * @param year the edition year
 * @returns react-query "useQuery" response
 */
export const useArticle = (article, title, year) => {
  const history = useHistory();
  const { docStage } = useContext(AdminContext);

  const isPublic = docStage === documentStagePublic;

  const articleUrl = isPublic
    ? `${API}/adminCodeArticle?edition_year=${year}&title_num=${title}&article_num=${article}&doc_stage=${docStage}`
    : `${API}/auth/adminCodeArticle?edition_year=${year}&title_num=${title}&article_num=${article}&doc_stage=${docStage}`;

  const possiblyAuthorized = isPublic
    ? () => getData(articleUrl)
    : () => getAuthData(history, articleUrl);

  return useQuery({
    queryKey: [adminCodeArticleKey, articleUrl, article],
    queryFn: possiblyAuthorized,
    enabled: !!article && !!title && !!year,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response?.iar_iac_article_doc
  });
};

const adminCodeArticlesKey = 'adminCodeArticle';
/**
 * Retrieve the articles for non-public document stages
 * @param stage the document stage (private or private_ready)
 * @returns react-query "useQuery" response
 */
export const useNonPublicArticles = (stage) => {
  const history = useHistory();
  const articlesUrl = `${API}/auth/adminCodeArticles?doc_stage=${stage}`;

  const authQueryFn = () => getAuthData(history, articlesUrl, []);

  return useQuery({
    queryKey: [adminCodeArticlesKey, articlesUrl],
    queryFn: authQueryFn,
    enabled: stage === documentStagePrivate || stage === documentStagePrivateReady,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response?.admin_code_articles
  });
};
const registerDocTypesKey = 'registerDocTypes';
/**
 * Retrieve a list of document types
 * @returns react-query "useQuery" response
 */
export const useDocumentTypes = () => {
  const docTypeUrl = `${API}/registerDocTypes`;

  return useQuery({
    queryKey: [registerDocTypesKey, docTypeUrl],
    queryFn: () => getData(docTypeUrl),
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    staleTime: Infinity
  });
};

const registerUpcomingKey = 'registerUpcoming';
/**
 * Retrieve a list of documents for upcoming comment period ends or public hearings.
 * @param endDate
 * @param dateType 'CP' for Comment Period or 'PH' for Public Hearing
 * @returns  react-query "useQuery" response
 */
export const useUpcomingDocuments = (endDate, dateType) => {
  const history = useHistory();
  const { docStage } = useContext(AdminContext);

  const isPublic = docStage === documentStagePublic;

  const today = formatISO(todayFn(), { representation: 'date' });
  const urlPath = isPublic ? '/registerUpcoming' : '/auth/registerUpcoming';
  const upcomingDocumentUrl = `${API}${urlPath}?start_date=${today}&end_date=${formatISO(endDate, {
    representation: 'date'
  })}&date_type=${dateType}&doc_stage=${docStage}`;

  const possiblyAuthorized = isPublic
    ? () => getData(upcomingDocumentUrl)
    : () => getAuthData(history, upcomingDocumentUrl);

  return useQuery({
    queryKey: [registerUpcomingKey, endDate, dateType, upcomingDocumentUrl],
    queryFn: possiblyAuthorized,
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) =>
      response?.iar_ir_upcoming.toSorted((a, b) => {
        let aDate = null;
        let bDate = null;
        if (dateType === 'CP') {
          aDate = parseServerDate(a.comment_period_deadline);
          bDate = parseServerDate(b.comment_period_deadline);
        } else if (dateType === 'PH') {
          aDate = parseServerDate(a.public_hearing_timestamp);
          bDate = parseServerDate(b.public_hearing_timestamp);
        }
        return compareAsc(aDate, bDate);
      })
  });
};

/**
 * Set up a mutation for making authorized API calls to
 * promote the docStage for a list of documents.
 *
 * (Note: the attempt to get a token will re-direct for
 * login if the token is missing or beyond the refresh timeout.)
 *
 * The mutation expects to be called with an object containing
 * - the ids (either "dins" or "articleIds")
 * - "promote" a boolean to pick between promote / revert actions.
 *
 * Example 1 -- to promote Admin Code Articles:
 *
 * <code>
 *   const mutation = useDocStageMutation();
 *   mutation.mutate({
 *     articleIds: ['id_1', 'id_2'],
 *     promote: true
 *   });
 * </code>
 *
 * Example 2 -- to promote Register Docs:
 *
 * <code>
 *   const mutation = useDocStageMutation();
 *   mutation.mutate({
 *     dins: ['din_1', 'din_2'],
 *     promote: true
 *   });
 * </code>
 *
 * Example 2 -- to revert Register Docs from private_ready to private:
 *
 * <code>
 *   const mutation = useDocStageMutation();
 *   mutation.mutate({
 *     dins: ['din_1', 'din_2'],
 *     promote: false
 *   });
 * </code>
 *
 * SIDE EFFECTS:
 * - Invalidates the "adminCodeArticlesKey" query, forcing the UI
 *   to re-fetch the list of articles for the admin page.
 * - Invalidates the "registerDocsKey" query, forcing the UI
 *   to re-fetch the list of register docs for the admin page.
 *
 * @returns the return value of react-query's "useMutation"
 */
export const useDocStageMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const authMutationFn = async ({ dins, articleIds, promote }) => {
    const token = await getToken(history);
    if (token) {
      const params = new URLSearchParams();
      params.append('dins', dins ?? '');
      params.append('article_ids', articleIds ?? '');
      params.append('promote', promote);

      const url = `${docStageUrl}?${params.toString()}`;
      return getData(url, token, 'PUT');
    }
    return null;
  };

  return useMutation({
    mutationFn: authMutationFn,

    onSuccess: () => {
      queryClient.invalidateQueries(adminCodeArticlesKey);
      queryClient.invalidateQueries(registerDocsKey);
    }
  });
};

export const useManageDocStage = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const manageDocStageKey = 'manageDocStage';
  const url = `${API}/auth/manageDocStage?doc_type=ir`;

  return useMutation({
    mutationFn: async () => {
      const token = await getToken(history);
      if (token) {
        return getData(url, token, 'POST');
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries(manageDocStageKey);
    }
  });
};
