import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { useLayoutEffect, useRef, useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import styles from './Banner.module.css';

/**
 * Display a banner at the top of the site. Currently, we're displaying a link to a PDF with old
 * Executive Orders.
 *
 * This was originally created to show a "this is beta" banner, but kept around because I knew we would
 * want something displayed up there again.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export const Banner = () => {
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const bannerRef = useRef(null);

  const updateOffset = () => {
    const rectangle = bannerRef.current.getBoundingClientRect();
    document.documentElement.style.setProperty('--bannerOffset', `${rectangle.height}px`);
  };

  // Watch for component rendering and page resizing to set the --bannerOffset custom prop.
  // That prop is needed to handle sticky and absolute positioning of various elements in the app.
  useLayoutEffect(() => {
    if (!bannerDismissed && bannerRef.current) {
      updateOffset();
      window.addEventListener('resize', updateOffset);
    }

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, [bannerDismissed]);

  const dismissBanner = () => {
    setBannerDismissed(true);
    document.documentElement.style.setProperty('--bannerOffset', '0rem');
    window.removeEventListener('resize', updateOffset);
  };

  if (bannerDismissed) {
    return null;
  }

  return (
    <div className={styles.banner} role="banner" ref={bannerRef}>
      <button
        onClick={dismissBanner}
        aria-label="dismiss the warning banner"
        className={styles.dismissButton}
      >
        <RiCloseLine />
      </button>
      <div className={styles.message}>
        To view a historical listing of Executive Orders please{' '}
        <ExternalLink
          url="/Historical-List-of-EOs.pdf"
          ariaLabel="link to list of Executive Orders"
        >
          click here
        </ExternalLink>
        .
      </div>
    </div>
  );
};
