/**
 * Overrides for variables from Colosseum, if necessary.
 */
export const Colors = {
  secondary: '#2B7DB3'
};

export const Typography = {
  bodyFont: 'Montserrat, normal',
  headingFont: 'Montserrat, normal'
};

export const roundSelectStyle = {
  control: {
    borderRadius: '1.5rem',
    paddingLeft: '1rem',
    textAlign: 'left',
    '&:has(+ div)': {
      borderRadius: '1.5rem 1.5rem 0 0'
    }
  },
  option: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'left'
  }
};
