import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * Redirect IR document links, such as "http://www.in.gov/legislative/iac/20241113-IR-045240432FNA.xml.html"
 * to our document page.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const RegisterDocumentRedirect = () => {
  const { din } = useParams();
  const history = useHistory();

  useEffect(() => {
    history.replace(`/register/${din}`);
  }, [din, history]);

  // Users should not see this, but just in case it doesn't properly redirect...
  return (
    <MainContentWrapper>
      <Link to={`/register/${din}`} aria-label={`link to IR doc ${din}`}>
        ${din}
      </Link>
    </MainContentWrapper>
  );
};
