import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useUpcomingDocuments } from 'hooks/documentHooks';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { inXDaysFn, toMmmD } from 'util/dateUtil';
import { displayLsaDocNumber } from 'util/documentUtil';

import styles from './Upcoming.module.css';

const dateRangeInDays = 60;

export const Hearings = () => {
  const [showAll, setShowAll] = useState(false);
  const { data, error, isLoading } = useUpcomingDocuments(inXDaysFn(dateRangeInDays), 'PH');
  const { getAgencyName } = useContext(RefDataContext);

  return (
    <div aria-labelledby="upcoming-public-hearings">
      <AccentHeader
        level={2}
        id="upcoming-public-hearings"
        subtitle={`Next ${dateRangeInDays} days`}
      >
        Upcoming Public Hearings
      </AccentHeader>
      {isLoading ? (
        <div className={styles.upcomingLoadingContainer}>
          <Loading screenreaderText="Loading List of Upcoming Public Hearings" />
        </div>
      ) : error ? (
        <p>Unable to load public hearing data.</p>
      ) : (
        <>
          {data?.length ? (
            <>
              <ol className={styles.upcomingItems}>
                {data.slice(0, showAll ? data?.length : 5).map((doc) => (
                  <li key={`hearing-${doc.din}`}>
                    <strong>{toMmmD(doc.public_hearing_timestamp)}</strong>{' '}
                    {getAgencyName(doc.title_num)}{' '}
                    <Link to={`/register/${doc.din}`}>
                      LSA {displayLsaDocNumber(doc.lsa_doc_num)}
                    </Link>
                  </li>
                ))}
              </ol>
              {data?.length > 5 ? (
                <div className={styles.buttonContainer}>
                  <Button action={() => setShowAll((cur) => !cur)} variants="hollow">
                    {showAll ? 'Show First 5' : `Show All ${data.length}`}
                  </Button>
                </div>
              ) : null}
            </>
          ) : (
            <div>No public hearings in the next {dateRangeInDays} days.</div>
          )}
        </>
      )}
    </div>
  );
};
