import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { RiDeleteBin2Fill, RiUpload2Fill } from 'react-icons/ri';

import styles from './FileUploader.module.css';

/**
 * Control for encapsulating a file input for INtake Agency Portal.
 * @param accept
 * @param allowMultiple
 * @param fieldName
 * @param label
 * @param selectedDocuments
 * @param setSelectedDocuments
 * @returns {JSX.Element}
 * @constructor
 */
export const FileUploader = ({
  accept,
  allowMultiple,
  fieldName,
  label,
  selectedDocuments,
  setSelectedDocuments
}) => {
  const docRef = useRef(null);
  const labelRef = useRef(null);
  const [isTargeted, setIsTargeted] = useState(false);

  // Read the files selected (either via file dialog or drag 'n' drop
  const readFiles = (files) => {
    if (files?.length) {
      Array.from(files).forEach((file, index) => {
        if (allowMultiple || index === 0) {
          if (accept.includes(file.type)) {
            setSelectedDocuments((cur) => {
              if (allowMultiple) {
                return [...cur, { name: file.name, data: file, mimeType: file.type }];
              }
              return [{ name: file.name, data: file, mimeType: file.type }];
            });
          } else {
            toast.error(`The format of ${file.name} is not applicable.`, {
              position: 'top-right'
            });
          }
        }
      });
    }
  };

  const deleteFile = (index) => {
    setSelectedDocuments((cur) => {
      const newList = [...cur];
      newList.splice(index, 1);
      return newList;
    });
  };

  // handle click / select files
  const handleFileInput = (e) => {
    readFiles(e.target.files);
  };

  // Drag 'n' drop start...
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    setIsTargeted(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsTargeted(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsTargeted(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    labelRef.current?.classList.remove(styles.target);
    setIsTargeted(false);
    readFiles(e.dataTransfer?.files);
  };
  // ...drag 'n' drop end

  return (
    <div className={styles.fileUploader}>
      <label
        className={
          isTargeted ? `${styles.fileSelectorLabel} ${styles.target}` : styles.fileSelectorLabel
        }
        htmlFor={`${fieldName}-documentChooser`}
        ref={labelRef}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <span className={styles.fileSelectorIcon}>
          <RiUpload2Fill size="1.5rem" aria-hidden={true} />
        </span>
        {label}
      </label>
      <input
        accept={accept?.length ? accept.join(',') : undefined}
        className={styles.fileSelector}
        id={`${fieldName}-documentChooser`}
        multiple={allowMultiple ?? false}
        name={fieldName}
        onInput={handleFileInput}
        ref={docRef}
        type="file"
      />
      {selectedDocuments?.length ? (
        <ul className={styles.selectedFileList}>
          {selectedDocuments.map((doc, index) => (
            <li key={`${fieldName}-${doc.name}`} className={styles.selectedFile}>
              <button
                aria-label={`Remove ${doc.name} from upload`}
                className={styles.deleteFileButton}
                onClick={() => deleteFile(index)}
                type="button"
              >
                <RiDeleteBin2Fill
                  aria-hidden={true}
                  className={styles.deleteFileIcon}
                  size="1.5rem"
                />
              </button>
              <span>{doc.name}</span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

FileUploader.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  allowMultiple: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  selectedDocuments: PropTypes.array,
  setSelectedDocuments: PropTypes.func.isRequired
};
