import { CodeArticleName } from 'components/DocumentAccordion/CodeArticleName/CodeArticleName';
import { CodeTitleName } from 'components/DocumentAccordion/CodeTitleName/CodeTitleName';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { codeTitle } from 'util/shapes';

import styles from './DocumentAccordion.module.css';

/**
 * An item for code navigation.
 *
 * @param isOpen Whether or not to be open on initialization
 * @param title The title and its list of articles
 * @param year the edition year
 * @returns {JSX.Element}
 * @constructor
 */
export const CodeTitleAccordion = ({ title, year }) => {
  const { article: articleParam, title: titleParam } = useParams();
  const [isAccordionOpen, setIsAccordionOpen] = useState(title.title_num === titleParam);
  const accordionRef = useRef(null);
  const articleRef = useRef(null);

  useEffect(() => {
    const isOpen = title.title_num === titleParam;
    setIsAccordionOpen(isOpen);
    if (isOpen) {
      // We need a short delay, as another accordion closing might affect the
      // position of THIS accordion.
      const id = setTimeout(() => {
        const position =
          articleParam && articleRef.current
            ? articleRef.current.offsetTop
            : accordionRef.current.offsetTop;
        accordionRef.current.parentElement.scrollTo({ behavior: 'smooth', top: position });
      }, 500);
      return () => clearTimeout(id);
    }
  }, [articleParam, title, titleParam]);

  const toggleAccordion = () => {
    setIsAccordionOpen((current) => !current);
  };

  return (
    <div className={`${styles.topLevelAccordion} ${styles.onlyDocs}`} ref={accordionRef}>
      <button
        aria-expanded={isAccordionOpen}
        className={styles.toggleButton}
        onClick={toggleAccordion}
      >
        <CodeTitleName number={title.title_num} title={title.title_name} />
        <RiArrowDownSLine
          size="2rem"
          className={styles.toggleButtonIcon}
          title="accordion toggle icon"
        />
      </button>
      <div className={styles.documents}>
        <nav className={styles.documentsContent} aria-label={`navigation for ${title.title_name}`}>
          {title.article.map((article) => (
            <NavLink
              activeClassName={styles.selected}
              className={styles.articleLink}
              key={`iac-title-article-${article.article_num}`}
              ref={article.article_num === articleParam ? articleRef : undefined}
              to={`/code/${year}/${title.title_num}/${article.article_num}`}
            >
              <CodeArticleName name={article.article_name} />
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

CodeTitleAccordion.propTypes = {
  isOpen: PropTypes.bool,
  title: codeTitle.isRequired,
  year: PropTypes.string.isRequired
};
