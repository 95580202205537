import { add, format, parse, parseISO, set } from 'date-fns';

export const displayFormat = 'MM/dd/yyyy';
export const urlFormat = 'yyyy-MM-dd';
const serverFormat = 'MM/dd/yyyy, HH:mm:ss';

export const todayFn = () => set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
export const sixDaysAgoFn = () => add(todayFn(), { days: -6 });
export const thirtyDaysAgoFn = () => add(todayFn(), { days: -30 });
export const sixMonthsAgoFn = () => add(todayFn(), { months: -6 });
export const oneYearFutureFn = () => add(todayFn(), { years: 1 });
export const earliestDataFn = () =>
  set(new Date(2006, 0, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const inXDaysFn = (x) => add(todayFn(), { days: x });

export const parseServerDate = (dateString) => parse(dateString, serverFormat, new Date());

export const toMmmD = (dateString) => {
  return format(parseServerDate(dateString), 'MMM. d');
};

export const isoToMDY = (dateString) => format(parseISO(dateString), displayFormat);
export const isoToMmmD = (dateString) => format(parseISO(dateString), 'MMM. d');

export const yearStartFn = (year) => new Date(year, 0, 1, 0, 0, 0, 0);

export const twoYearsAgoFn = () => {
  const today = todayFn();
  const twoYearsAgo = add(today, { months: -24 });
  return twoYearsAgo;
};
