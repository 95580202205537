import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { encodedLsaDocNumber } from 'util/documentUtil';

/**
 * Handle redirects to the Register page or a Related documents page (depends on the params).
 * If an `lsadocnum` param is present, it will encode the param and redirect to the Related Docs
 * Page for the specified LSA document number. Otherwise, it will show the main Register page,
 * possibly with a custom date range (of `ldate` and `hdate` params are present.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const RegisterRedirect = () => {
  const [state, setState] = useState({ fromDate: '', link: '', lsaDocNumber: '', toDate: '' });
  const { search } = useLocation();
  const history = useHistory();

  const encodeDate = (dateParam) => {
    return (
      dateParam.substring(0, 4) + '-' + dateParam.substring(4, 6) + '-' + dateParam.substring(6)
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const lsaDocNumber = params.get('lsadocnum');
    const lsaDocYear = params.get('lsayear');
    const lsaNumber = params.get('lsadoc');
    const fromDateParam = params.get('ldate');
    const toDateParam = params.get('hdate');
    const fromDate = fromDateParam ? encodeDate(fromDateParam) : '';
    const toDate = toDateParam ? encodeDate(toDateParam) : '';
    let link;

    if (lsaDocNumber) {
      const encoded = lsaDocNumber ? encodedLsaDocNumber(lsaDocNumber) : '';
      link = `/lsadoc/${encoded}`;
    } else if (lsaDocYear && lsaNumber) {
      const encoded = encodedLsaDocNumber(`${lsaDocYear}-${lsaNumber}`);
      link = `/lsadoc/${encoded}`;
    } else {
      let queryString = '';
      if (fromDate && toDate) {
        queryString = `?range=custom&from=${fromDate}&to=${toDate}`;
      }
      link = `/register${queryString}`;
    }

    setState({ fromDate, link, lsaDocNumber, toDate });
    history.replace(link);
  }, [history, search]);

  // Users should not see this, but just in case it doesn't properly redirect...
  return (
    <MainContentWrapper>
      <Link to={state.link} aria-label={`link to ${state.lsaDocNumber ?? 'Indiana Register'}`}>
        {state.lsaDocNumber ?? 'Indiana Register'}
      </Link>
    </MainContentWrapper>
  );
};
