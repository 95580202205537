import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';
import { RiCheckFill, RiErrorWarningFill, RiLoader3Fill } from 'react-icons/ri';

import styles from './UploadStatusIcon.module.css';

/**
 * Encapsulate the status icon (or loader)
 * @param status
 * @returns {JSX.Element}
 * @constructor
 */
export const UploadStatusIcon = ({ status }) => {
  if (status === 'active') {
    return <Spinner />;
  }

  if (status === 'complete') {
    return <RiCheckFill className={styles.success} size="1.5rem" />;
  }

  if (status === 'error') {
    return <RiErrorWarningFill className={styles.error} size="1.5rem" />;
  }

  return <RiLoader3Fill className={styles.pending} size="1.5rem" />;
};

UploadStatusIcon.propTypes = {
  status: PropTypes.oneOf(['active', 'complete', 'error'])
};
