import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { DocumentAccordion } from 'components/DocumentAccordion/DocumentAccordion';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { SearchButton } from 'components/SearchButton/SearchButton';
import { format, parseISO } from 'date-fns';
import { useIrDocs } from 'hooks/documentHooks';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router';
import { sixDaysAgoFn, sixMonthsAgoFn, thirtyDaysAgoFn, todayFn, urlFormat } from 'util/dateUtil';
import { dateRanges, documentStagePublic } from 'util/shapes';

import { RegisterFilterForm } from './RegisterFilterForm/RegisterFilterForm';
import styles from './RegisterList.module.css';

const today = todayFn();
const sixDaysAgo = sixDaysAgoFn();

/**
 * Convert the query string (or sessionStorage value) to initial values for the form.
 * Default to the last seven days.
 *
 * @param queryString
 * @returns {{selection: (string|string), from: Date, to: Date}}
 */
const initializeDateRange = (queryString) => {
  const params = new URLSearchParams(
    queryString || sessionStorage.getItem('registerQuery') || null
  );
  const selection = params.get('range') || dateRanges.sevenDays;
  const fromParam = params.get('from');
  const toParam = params.get('to');
  let from = sixDaysAgo;
  let to = today;
  if (selection === dateRanges.custom) {
    from = fromParam ? parseISO(fromParam) : from;
    to = toParam ? parseISO(toParam) : to;
  } else if (selection === dateRanges.sixMonths) {
    from = sixMonthsAgoFn();
  } else if (selection === dateRanges.thirtyDays) {
    from = thirtyDaysAgoFn();
  }

  return { from, to, selection };
};

export const RegisterList = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [displayedDateRange, setDisplayedDateRange] = useState(initializeDateRange(search));

  const {
    data: docs,
    error,
    isLoading
  } = useIrDocs(displayedDateRange?.from, displayedDateRange?.to, documentStagePublic);

  const handleFormChange = (formData) => {
    let dates = '';
    // Only add the dates if the range is "custom". Prevents oddities like having a "last 30 days"
    // selection with dates that are not for the last 30 days.
    if (formData.selection === dateRanges.custom) {
      dates = `&from=${format(formData.from, urlFormat)}&to=${format(formData.to, urlFormat)}`;
    }
    const registerQuery = `?range=${formData.selection}${dates}`;
    // set the querystring in sessionStorage for future top-level navigation.
    sessionStorage.setItem('registerQuery', registerQuery);
    // replace the querystring, for bookmarking/sharing links
    history.replace(`/register${registerQuery}`);
    // update internal state, and kick off new query
    setDisplayedDateRange(formData);
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Indiana Register List | IARP</title>
      </Helmet>
      <SearchButton
        params={{
          dateRange: displayedDateRange.selection,
          startDate: displayedDateRange.from,
          endDate: displayedDateRange.to
        }}
      />
      <AccentHeader level={1}>Indiana Register</AccentHeader>
      <RegisterFilterForm
        initialDateRange={displayedDateRange}
        setDisplayedDateRange={handleFormChange}
      />
      <div aria-label="RegisterList Document List" aria-live="polite">
        {isLoading ? (
          <div className={styles.docsLoadingWrapper}>
            <Loading screenreaderText="Loading List of Register Documents" />
          </div>
        ) : error ? (
          <ErrorMessage>Error while retrieving documents.</ErrorMessage>
        ) : docs?.length ? (
          <>
            {docs.map((docType) => (
              <DocumentAccordion
                key={`accordion-${docType.cat_id}`}
                documentType={docType}
                isTop={true}
              />
            ))}
          </>
        ) : (
          <p>No documents for you, tough guy!</p>
        )}
      </div>
    </MainContentWrapper>
  );
};
