import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useDocumentSubmissions } from 'hooks/INtakeHooks';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { INtakeDocumentSubmission } from './INtakeDocumentSubmission/INtakeDocumentSubmission';
import styles from './IntakeReceipts.module.css';

export const INtakeSubmission = () => {
  const { data, error, isLoading } = useDocumentSubmissions();
  const [augmentedData, setAugmentedData] = useState(null);
  const { getDocTypeNameById } = useContext(RefDataContext);
  const [filterString, setFilterString] = useState('');

  useEffect(() => {
    // we only have the doctype ID, but we need the name for display and filtering.
    if (!isLoading && data?.length > 0) {
      const dataWithTypeNames = data.map((item) => {
        return {
          ...item,
          docType: getDocTypeNameById(item.type)
        };
      });
      setAugmentedData(dataWithTypeNames);
    } else {
      setAugmentedData([]);
    }
  }, [data, getDocTypeNameById, isLoading]);

  const filterDocs = (doc) => {
    return (
      doc.docType?.toLowerCase().includes(filterString) ||
      doc.lsa_doc_number?.toLowerCase().includes(filterString) ||
      doc.title?.toLowerCase().includes(filterString)
    );
  };

  return (
    <>
      <Helmet>
        <title>INtake Document Submission | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        INtake Document Submission
      </AccentHeader>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : error ? (
        <div>
          <ErrorMessage>Unable to load Document Submissions</ErrorMessage>
        </div>
      ) : (
        <div>
          {augmentedData?.length ? (
            <>
              <input
                className={styles.filterInput}
                onChange={(e) => setFilterString(e.target.value?.toLowerCase() ?? '')}
                placeholder="Filter..."
                type="search"
              />
              <p>{augmentedData.length} Submissions Ready to Process</p>
              <ul className={styles.receiptList}>
                {augmentedData?.filter(filterDocs).map((doc) => (
                  <INtakeDocumentSubmission doc={doc} key={`docSubmission-${doc.id}`} />
                ))}
              </ul>
            </>
          ) : (
            <p>No Submissions Ready</p>
          )}
        </div>
      )}
    </>
  );
};
