import { Loading, Select } from 'colosseum';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useTitleList } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { roundSelectStyle } from 'util/ThemeOverrides';

import styles from './AgencyPortalForm.module.css';
import { FileUploader } from './FileUploader/FileUploader';

const initialErrors = {
  title: null,
  docType: null,
  primaryDoc: null,
  lsaNumber: null
};

export const AgencyPortalForm = ({ onSubmit }) => {
  const { currentEdition } = useContext(RefDataContext);
  const { data: allTitles, isLoading: isTitleDataLoading } = useTitleList(currentEdition);
  const { documentTypes } = useContext(RefDataContext);
  const [primaryDocuments, setPrimaryDocuments] = useState([]);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [errors, setErrors] = useState(initialErrors);

  const submitForm = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const title = data.get('title');
    const notes = data.get('notes');
    const docType = data.get('docType');
    const lsaNumber = data.get('lsaNumber');
    if (primaryDocuments?.length === 1 && e.target.checkValidity()) {
      setErrors(initialErrors);
      onSubmit({
        title,
        notes,
        docType,
        lsaNumber,
        primaryDocument: primaryDocuments[0],
        supportingDocuments
      });
      e.target.reset();
      setPrimaryDocuments([]);
      setSupportingDocuments([]);
    } else {
      setErrors({
        title: title ? '' : 'Required',
        docType: docType ? '' : 'Required',
        primaryDoc: primaryDocuments?.length === 1 ? '' : 'Required',
        lsaNumber: lsaNumber ? '' : 'Required'
      });
    }
  };

  if (isTitleDataLoading) {
    return <Loading />;
  }

  return (
    <form
      className={styles.uploadForm}
      encType="multipart/form-data"
      noValidate
      onSubmit={submitForm}
    >
      <div className={styles.inputRow}>
        <label className={errors.title ? styles.requiredItemLabel : null} htmlFor="titleSelection">
          Title
        </label>
        <Select
          customStyles={roundSelectStyle}
          id="titleSelection"
          name="title"
          options={allTitles}
          placeholder="Select Title..."
          required={true}
        />
      </div>
      <div className={styles.multiInputRow}>
        <div className={styles.multiInputs}>
          <div>
            <label className={errors.docType ? styles.requiredItemLabel : null} htmlFor="docType">
              Document Type
            </label>
            <Select
              customStyles={roundSelectStyle}
              id="docType"
              name="docType"
              options={documentTypes.all.docTypes.map((docType) => {
                return { label: docType.fullName, value: `${docType.id}` };
              })}
              placeholder="Select Document Type..."
              required={true}
            />
          </div>
          <div>
            <label
              htmlFor="lsaNumber"
              className={errors.lsaNumber ? styles.requiredItemLabel : null}
            >
              LSA Number
            </label>
            <input type="text" name="lsaNumber" id="lsaNumber" className={styles.input} required />
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <FileUploader
          accept={[
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ]}
          allowMultiple={false}
          fieldName="rulemakingDocument"
          label={
            <span className={errors.primaryDoc ? styles.requiredItemLabel : null}>
              Upload Agency Document in <strong>Word Format</strong> Only
            </span>
          }
          selectedDocuments={primaryDocuments}
          setSelectedDocuments={setPrimaryDocuments}
        />
      </div>
      <div className={styles.inputRow}>
        <FileUploader
          accept={['application/pdf']}
          allowMultiple={true}
          fieldName="supportingDocuments"
          label={
            <span>
              Upload Supporting Documents in <strong>PDF</strong> Only
            </span>
          }
          selectedDocuments={supportingDocuments}
          setSelectedDocuments={setSupportingDocuments}
        />
      </div>
      <div className={styles.multiInputRow}>
        <div className={styles.multiInputs}>
          <div>
            <label htmlFor="notes" className={styles.itemLabel}>
              Notes
            </label>
            <textarea id="notes" name="notes" rows={5} className={styles.textarea} />
          </div>
          <Button type="submit">Submit</Button>
        </div>
      </div>
    </form>
  );
};

AgencyPortalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
