import { Button } from 'components/Button/Button';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { RiEdit2Line, RiFilePdf2Fill, RiFileWordFill } from 'react-icons/ri';

import styles from './INtakeDocumentSubmission.module.css';

/**
 * The individual document receipt
 * @param doc
 * @returns {JSX.Element}
 * @constructor
 */
export const INtakeDocumentSubmission = ({ doc }) => {
  const dialogRef = useRef(null);
  const openEditor = () => {
    dialogRef.current?.showModal();
  };

  const convertLink = (s3Url) => {
    return s3Url.replace('s3://dev-iar-site-doc-storage/', process.env.REACT_APP_INTAKE_BASE);
  };

  return (
    <>
      <li className={styles.docReceiptContainer}>
        <div className={styles.docReceipt}>
          <span className={styles.lsaNumber}>
            <button
              className={styles.editButton}
              aria-label="Edit Receipt Details"
              aria-haspopup="dialog"
              type="button"
              onClick={openEditor}
            >
              <RiEdit2Line />
            </button>{' '}
            {doc.lsa_doc_number}
          </span>
          <span className={styles.titleAndType}>
            Title {doc.title}, {doc.docType}
          </span>
          <span className={styles.submitDate}>Submitted: {doc.upload_timestamp}</span>
          <ExternalLink
            additionalClassName={styles.intakeLink}
            linkText="Open in INtake"
            url={`https://google.com/search?q=${doc.id}`}
          />
        </div>
      </li>
      <dialog className={styles.editDialog} ref={dialogRef}>
        <ul>
          <li>ID: {doc.id}</li>
          <li>LSA Number: {doc.lsa_doc_number}</li>
          <li>Title: {doc.title}</li>
          <li>Type: {doc.docType}</li>
          <li>
            Submitted: {doc.upload_timestamp} by {doc.upload_user_id}
          </li>
          {doc.link ? (
            <li>
              <a
                download={doc.filename}
                href={convertLink(doc.link)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <RiFileWordFill /> {doc.filename}
              </a>
            </li>
          ) : null}
          {doc.children ? (
            <li>
              Supporting Documents
              <ul>
                {doc.children.map((child) => (
                  <li key={`supportingDoc-${doc.id}-${child.id}`}>
                    <a
                      download={child.filename}
                      href={convertLink(child.link)}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <RiFilePdf2Fill /> {child.filename}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ) : null}
        </ul>
        <div className={styles.dialogButtons}>
          <Button action={() => dialogRef.current.close()} ariaLabel="close dialog">
            OK
          </Button>
        </div>
      </dialog>
    </>
  );
};

INtakeDocumentSubmission.propTypes = {
  doc: PropTypes.shape({
    docType: PropTypes.string,
    filename: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lsa_doc_number: PropTypes.string.isRequired,
    upload_timestamp: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    upload_user_id: PropTypes.number.isRequired,
    link: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        filename: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
      })
    )
  }).isRequired
};
