import { SpinningButton } from 'components/SpinningButton/SpinningButton';
import { useIrDocument } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { downloadPDF } from 'util/documentUtil';

import styles from './DownloadRegisterPdfButton.module.css';

export const DownloadRegisterPdfButton = ({ din }) => {
  // We want to fetch manually, when the user clicks
  const { refetch } = useIrDocument(din, true);
  const [renderingPDF, setRenderingPDF] = useState(false);
  const documentRef = useRef(null);
  const documentBodyRef = useRef(null);
  const postedDateRef = useRef(null);

  const downloadIrDocPdf = async (xml, din) => {
    try {
      await downloadPDF(din, xml);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setRenderingPDF(false);
    }
  };

  const handleClick = () => {
    setRenderingPDF(true);
    refetch().then(async ({ data }) => {
      if (data && documentRef.current) {
        await downloadIrDocPdf(data.doc_xml, data.din);
      }
    });
  };

  return (
    <>
      <SpinningButton
        ariaLabel="Download as PDF"
        ariaSpinningLabel="Downloading PDF"
        buttonLabel="PDF"
        onClick={handleClick}
        isSpinning={renderingPDF}
      />
      <span className={styles.hiddenDocument}>
        <article className="documentWrapper print" ref={documentRef}>
          <div ref={documentBodyRef} />
          <div className="documentPostedDate">
            Posted: <span ref={postedDateRef} /> by Legislative Services Agency
          </div>
        </article>
      </span>
    </>
  );
};

DownloadRegisterPdfButton.propTypes = {
  din: PropTypes.string
};
