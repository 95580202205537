import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AppFooter.module.css';

export const AppFooter = ({ sticky }) => {
  return (
    <footer className={sticky ? `${styles.footer} ${styles.sticky}` : styles.footer}>
      <nav className={styles.footerLinks} aria-label="Footer">
        <ExternalLink
          className={styles.footerLink}
          linkText="Administrative Drafting Manual"
          url="/IACDrftMan.pdf"
        />
        <ExternalLink
          className={styles.footerLink}
          linkText="Appendix D - Emergency Rule Authorities"
          url="/appendix-d-emergency-rule-authorities.pdf"
        />
        <ExternalLink
          className={styles.footerLink}
          linkText="Submissions to Attorney General's Office"
          url="/Submissions-to-Attorney-Generals-Office.pdf"
        />
        <ExternalLink
          className={styles.footerLink}
          linkText="General Assembly"
          url="https://iga.in.gov"
        />
        <ExternalLink
          className={styles.footerLink}
          linkText="Agency List"
          url="https://www.in.gov/core/find_agency.html"
        />
        <ExternalLink
          className={styles.footerLink}
          linkText="LSA Rulemaking Templates"
          url="https://www.in.gov/omb/rule-approval-process/lsa-templates/"
        />
        <Link to="/contact" className={styles.footerLink}>
          Contact/About Us
        </Link>
        <a href="/sitemap.xml" className={styles.footerLink}>
          Site Map
        </a>
      </nav>
    </footer>
  );
};

AppFooter.propTypes = {
  sticky: PropTypes.bool
};
