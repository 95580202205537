import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useOpenSearch } from 'hooks/openSearchHook';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isoToMDY, todayFn, twoYearsAgoFn } from 'util/dateUtil';
import { docGroup } from 'util/documentUtil';

import styles from './ActionsAndOpinions.module.css';

/**
 * Show the year's list for the associated search tab.
 * @param label
 * @param tab
 * @returns {JSX.Element}
 * @constructor
 */
export const ActionOrOpinionList = ({ label, tab }) => {
  const { getDocTypeName } = useContext(RefDataContext);
  const { data, error, executeSearch, isSearching } = useOpenSearch();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    executeSearch({
      archived: false,
      document_group: docGroup[tab],
      endDate: todayFn(),
      size: 1000, // this should be more than large enough to get all, since we're not using paging
      startDate: twoYearsAgoFn()
    });
  }, [executeSearch, tab]);

  return (
    <div aria-labelledby="section-label">
      <AccentHeader level={2} id="section-label" subtitle="Previous 24 months">
        {label}
      </AccentHeader>
      {isSearching ? (
        <div className={styles.loadingWrapper}>
          <Loading screenreaderText="Loading Publisher's Receipts" />
        </div>
      ) : error ? (
        <p>Unable to retrieve Governor&apos;s Actions.</p>
      ) : data?.hits?.hits?.length ? (
        <>
          <ol className={styles.actionsList}>
            {data.hits.hits.slice(0, showAll ? data.hits.hits.length : 5).map((doc) => (
              <li key={`${tab}-${doc._source.din}`} className={styles.listItem}>
                <strong>{isoToMDY(doc._source.date_posted)}</strong>
                <span>{getDocTypeName(doc._source.doc_type)}</span>
                <Link to={`/register/${doc._source.din}`} className={styles.lsaLink}>
                  LSA {doc._source.lsa_doc_num_display}
                </Link>
              </li>
            ))}
          </ol>
          <div className={styles.buttonContainer}>
            {data.hits.hits.length > 5 ? (
              <Button action={() => setShowAll((cur) => !cur)} variants="hollow">
                {showAll ? 'Show 5 Most Recent' : `Show Next ${data.hits.hits.length - 5}`}
              </Button>
            ) : null}
            <Link className={styles.olderButton} to={`/search?archived=true#${tab}`}>
              Search All
            </Link>
          </div>
        </>
      ) : (
        <div>
          <p>No {label} this year.</p>
          <Link className={styles.olderButton} to={`/search?archived=true#${tab}`}>
            Search All
          </Link>
        </div>
      )}
    </div>
  );
};

ActionOrOpinionList.propTypes = {
  label: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired
};
