import { ActionOrOpinionList } from 'pages/Home/ActionsAndOpinions/ActionOrOpinionList';

import styles from './ActionsAndOpinions.module.css';

export const ActionsAndOpinions = () => {
  return (
    <section
      className={styles.actionsOpinionsContainer}
      aria-label={"This year's Governor's actions and Attorney General Opinions"}
    >
      <ActionOrOpinionList label="Governor's Actions" tab="gov" />
      <ActionOrOpinionList label="Attorney General's Opinions" tab="ag" />
    </section>
  );
};
